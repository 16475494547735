import React from "react"
import styled from "@emotion/styled"

const ShopStyle = styled("div")`
  width: 100%;
  text-align: center;
  transition: 0.3s ease-in-out;
  color: white;
  display: flex;
  justify-content: center;

  &:hover {
    transform: scale(1.1);
    opacity: 0.8;
  }
`
const Promo = styled("h2")`
  font-family: "Bebas Neue", Arial;
  color: white;
  text-align: center;
  font-size: 50px;
  margin: 0;
  width: 100%;
  letter-spacing: 1px;
  font-weight: 100;
  border: white 3px solid;
  padding: 1rem 2rem;
`

export default () => (
  <ShopStyle>
    <a href="https://shop.aleyauk.com/" target="_blank" rel="noreferrer">
      <Promo>Shop</Promo>
    </a>
  </ShopStyle>
)
