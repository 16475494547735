import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Icon = styled("li")`
  width: 36px;
  display: flex;
  align-items: center;
  margin: 0 15px;
  transition: 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }

  img {
    width: 100%;
  }
`

const IconList = styled("ul")`
  list-style: none;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 0;
`

const Social = ({ links }) => (
  <IconList>
    {links.map((link, index) => {
      if(link.enabled){
        return(
          <Icon key={index}>
          <a href={link.link.url} target="_blank" without rel="noreferrer">
            <img src={link.icon.url} alt={link.icon.alt} />
          </a>
        </Icon>
        )
      }
      else{
        return null;
      }
    })}
  </IconList>
)

export default Social

Social.propTypes = {
  links: PropTypes.array.isRequired,
}
