import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const Video = styled("div")`
  position: relative;
  width: 100%;

  div {
    overflow: hidden;
    padding-bottom: 56.25%;
    height: 0;
  }

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`

const Youtube = ({ videoid }) => (
  <Video>
    <div>
      <iframe
        width="420"
        height="315"
        src={`https://www.youtube.com/embed/${videoid}`}
        frameborder="0"
        allowFullScreen
        title="Youtube"
      ></iframe>
    </div>
  </Video>
)

export default Youtube

Youtube.propTypes = {
  videoid: PropTypes.string.isRequired,
}
