import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions"
import Layout from "components/Layout"
import Social from "components/Social"
import Stores from "components/Stores"
import Spotify from "components/Spotify"
import Youtube from "components/Youtube"
import Shop from "components/Shop"
import Promo from "components/Promo"
import icon from "images/ALEYA - ICON FINAL (BLACK).png"

const Section = styled("div")`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: ${dimensions.maxwidthDesktop}px;
  margin: 2em auto;
  padding-left: ${dimensions.paddingHorizontalDesktop}em;
  padding-right: ${dimensions.paddingHorizontalDesktop}em;

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    padding-left: ${dimensions.paddingHorizontalTablet}em;
    padding-right: ${dimensions.paddingHorizontalTablet}em;
    margin-bottom: 1em;
    flex-direction: column;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    padding-left: ${dimensions.paddingHorizontalMobile}em;
    padding-right: ${dimensions.paddingHorizontalMobile}em;
  }

  &:last-of-type {
    margin-bottom: 0;
  }
`

const WideSection = styled(Section)`
  max-width: ${dimensions.maxwidthDesktop * 1.2}px;
  justify-content: center;
`

const BandPhoto = styled(Section)`
  max-width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 25%),
    url("${(props) => props.imageUrl}");
  background-size: cover;
  background-position: top;
  height: 1000px;

  @media (max-width: ${dimensions.maxwidthDesktop}px) {
    height: 800px;
  }

  @media (max-width: ${dimensions.maxwidthTablet}px) {
    height: 600px;
  }

  @media (max-width: ${dimensions.maxwidthMobile}px) {
    height: 300px;
  }
`

const Column = styled("div")`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-items: center;
  flex: 1;
  padding: 20px;
`

const Album = styled("img")`
  max-width: 500px;
  width: 100%;
`

const RenderBody = ({ home, meta }) => (
  <>
    <Helmet
      title={home.meta_title}
      link={[{ rel: "icon", type: "image/png", href: icon }]}
      meta={[
        {
          name: `description`,
          content: home.meta_description,
        },
        {
          property: `og:title`,
          content: home.meta_title,
        },
        {
          property: `og:description`,
          content: home.meta_description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: home.og_image.url,
        },
      ].concat(meta)}
    />
    <Section>
      <Shop />
    </Section>
    <Section>
      <Social links={home.social_links} />
    </Section>
    <Section>
      <Promo>{home.tagline}</Promo>
    </Section>
    <Section>
      <Column>
        <Album src={home.album_art.url} />
      </Column>
      <Column>
        {home.enable_spotify && <Spotify uri={home.spotify_id} />}

        <Stores links={home.store_links} />
      </Column>
    </Section>
    <WideSection>
      {home.enable_youtube && <Youtube videoid={home.youtube_id} />}
    </WideSection>

    {home.footer_image != null && (
      <BandPhoto imageUrl={home.footer_image.url}></BandPhoto>
    )}
  </>
)

export default ({ data }) => {
  const doc = data.prismic.allHomepages.edges.slice(0, 1).pop()
  const meta = data.site.siteMetadata

  if (!doc) return null

  return (
    <Layout>
      <RenderBody home={doc.node} meta={meta} />
    </Layout>
  )
}

RenderBody.propTypes = {
  home: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
}

export const query = graphql`
  {
    prismic {
      allHomepages {
        edges {
          node {
            footnote
            tagline
            header_image
            footer_image
            enable_youtube
            enable_spotify
            spotify_id
            spotify_uri
            album_art
            youtube_id
            meta_title
            meta_description
            og_image
            store_links {
              image
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              enabled
            }
            social_links {
              icon
              link {
                ... on PRISMIC__ExternalLink {
                  url
                }
              }
              enabled
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`
