import React from "react"
import PropTypes from "prop-types"
import dimensions from "styles/dimensions"

const Spotify = ({ uri }) => (
  <>
    {uri && (
      <iframe
        src={`https://open.spotify.com/embed/${uri}`}
        width={
          typeof window !== "undefined" &&
          window.innerWidth > dimensions.maxwidthMobile
            ? "400"
            : "315"
        }
        height="80"
        frameBorder="0"
        allowtransparency="true"
        allow="encrypted-media"
        title="spotify"
      ></iframe>
    )}
  </>
)

export default Spotify

Spotify.propTypes = {
  uri: PropTypes.string.isRequired,
}
